import './app.scss';

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { LazyPageComponent } from '@clodeo/clodeo-ui/components/page/lazy/lazy-page.component';
import AuthRoute from './components/templates/router/auth/router-auth.component';
import {
  ALL,
  EXPORT_PATH,
  FAILED,
  IMPORT_PATH,
  PRODUCT_DETAIL_PATH,
  PRODUCT_LINK_PATH,
  PRODUCT_UNLINK_PATH,
  PROGRESS,
  SUCCESS,
  VALIDATE,
} from './components/units/navigations/navigation-import-export/import-export-navigation.constant';
import { LINK_PATH } from './components/units/navigations/navigation-link-unlink/link-unlink-navigation.constant';
import {
  AUTHENTICATION_PATH,
  CHANNEL_PATH,
  CONFIGURATION_PATH,
  CREATE,
  DASHBOARD_PATH,
  DETAIL,
  PLUGIN_PATH,
  PRODUCT_PATH,
  ORDER_PATH,
  STOCK_PATH,
  PROGRESS_PATH,
  UPDATE,
} from './components/units/navigations/navigation-main/navigation-main.constant';
const pageNotFoundComponent = React.lazy(() => import('./components/templates/error-page/404.component'));
const unautorizeComponent = React.lazy(() => import('./components/templates/error-page/401.component'));
const dashboardComponent = React.lazy(() => import('./features/dashboard/dashboard.component'));
const pluginComponent = React.lazy(() => import('./features/plugin/plugin.component'));
const detailProductComponent = React.lazy(() => import('./features/product/detail-product/detail-product.component'));
const checkExportComponent = React.lazy(() => import('./features/product/export/export-product/check-export/check-export.component'));
const exportFailedComponent = React.lazy(() => import('./features/product/export/export-product/export-failed/export-failed.component'));
const exportProgressComponentPage = React.lazy(() => import('./features/product/export/export-product/export-progress/export-progress.component'));
const exportSuccessComponent = React.lazy(() => import('./features/product/export/export-product/export-success/export-success.component'));
const validateExportComponent = React.lazy(() => import('./features/product/export/export-product/validate-export/validate-export.component'));
const checkImportComponent = React.lazy(() => import('./features/product/import/import-product/check-import/check-import.component'));
const importFailedComponent = React.lazy(() => import('./features/product/import/import-product/import-failed/import-failed.component'));
const importProgressComponentPage = React.lazy(() => import('./features/product/import/import-product/import-progress/import-progress.component'));
const importSuccessComponent = React.lazy(() => import('./features/product/import/import-product/import-success/import-success.component'));
const validateImportComponent = React.lazy(() => import('./features/product/import/import-product/validate-import/validate-import.component'));
const checkLinkProductComponent = React.lazy(() => import('./features/product/link-product/check-link-product/check-link-product.component'));
const unLinkProductComponent = React.lazy(() => import('./features/product/unlink-product/list-unlink.component'));
const failedLinkProductComponent = React.lazy(() => import('./features/product/link-product/failed-link-product/failed-link-product.component'));
const progressLinkProductComponent = React.lazy(() => import('./features/product/link-product/progress-link-product/progress-link-product.component'));
const successLinkProductComponent = React.lazy(() => import('./features/product/link-product/success-link-product/success-link-product.component'));
const validateLinkProduct = React.lazy(() => import('./features/product/link-product/validate-link-product/validate-link-product.component'));
const listProductComponent = React.lazy(() => import('./features/product/list/base/product-base-list.component'));
const listOrderComponent = React.lazy(() => import('./features/order/list-order/list-order.component'));
const listStockComponent = React.lazy(() => import('./features/stock/list-stock/list-stock.component'));
const progressComponent = React.lazy(() => import('./features/progress/progress.component'));
const authenticationComponent = React.lazy(() => import('./features/authentication/authentication.component'));
const configurationCreate = React.lazy(() => import('./features/configurations/create/configuration-create.component'));
const configurationDetail = React.lazy(() => import('./features/configurations/detail/configuration-detail.component'));
const configurationUpdate = React.lazy(() => import('./features/configurations/update/configuration-update.component'));
const authenticationRFComponent = React.lazy(() => import('./features/authentication/authentication-rf.component'));
const channelAccountListComponent = React.lazy(() => import('./features/channel/list/account/channel-account-list.component'));
const channelListComponent = React.lazy(() => import('./features/channel/list/channel-list.component'));
const channelCreateComponent = React.lazy(() => import('./features/channel/create/channel-create.component'));
const channelUpdateComponent = React.lazy(() => import('./features/channel/update/channel-update.component'));
const productImportListComponent = React.lazy(() => import('./features/product/import/rf-product-import.component'));

export function AppRouter() {
  return (
    <>
      <Router>
        <Suspense fallback={<LazyPageComponent />}>
          <Switch>
            {/* Export */}
            <AuthRoute
              path={`${EXPORT_PATH}/${ALL}`}
              exact
              component={checkExportComponent}
            />
            <AuthRoute
              path={`${EXPORT_PATH}/${VALIDATE}`}
              exact
              component={validateExportComponent}
            />
            <AuthRoute
              path={`${EXPORT_PATH}/${SUCCESS}`}
              exact
              component={exportSuccessComponent}
            />
            <AuthRoute
              path={`${EXPORT_PATH}/${FAILED}`}
              exact
              component={exportFailedComponent}
            />
            <AuthRoute
              path={`${EXPORT_PATH}/${PROGRESS}`}
              exact
              component={exportProgressComponentPage}
            />
            {/* Import */}
            <AuthRoute
              path={PRODUCT_DETAIL_PATH}
              exact
              component={detailProductComponent}
            />
            <AuthRoute
              path={`${IMPORT_PATH}/${ALL}`}
              exact
              component={checkImportComponent}
            />
            <AuthRoute
              path={`${IMPORT_PATH}/${ALL}/new`}
              exact
              component={productImportListComponent}
            />
            <AuthRoute
              path={`${IMPORT_PATH}/${VALIDATE}`}
              exact
              component={validateImportComponent}
            />
            <AuthRoute
              path={`${IMPORT_PATH}/${SUCCESS}`}
              exact
              component={importSuccessComponent}
            />
            <AuthRoute
              path={`${IMPORT_PATH}/${FAILED}`}
              exact
              component={importFailedComponent}
            />
            <AuthRoute
              path={`${IMPORT_PATH}/${PROGRESS}`}
              exact
              component={importProgressComponentPage}
            />
            {/* Unlink Product */}
            <AuthRoute
              path={PRODUCT_UNLINK_PATH}
              exact
              component={unLinkProductComponent}
            />
            {/* Link Product */}
            <AuthRoute
              path={PRODUCT_LINK_PATH}
              exact
              component={checkLinkProductComponent}
            />
            <AuthRoute
              path={`${LINK_PATH}/${VALIDATE}`}
              exact
              component={validateLinkProduct}
            />
            <AuthRoute
              path={`${LINK_PATH}/${SUCCESS}`}
              exact
              component={successLinkProductComponent}
            />
            <AuthRoute
              path={`${LINK_PATH}/${FAILED}`}
              exact
              component={failedLinkProductComponent}
            />
            <AuthRoute
              path={`${LINK_PATH}/${PROGRESS}`}
              exact
              component={progressLinkProductComponent}
            />
            {/* Progress */}
            <AuthRoute
              path={`${PROGRESS_PATH}`}
              exact
              component={progressComponent}
            />
            {/* DASHBOARD */}
            <AuthRoute
              path={`${DASHBOARD_PATH}`}
              exact
              component={dashboardComponent}
            />
            {/* CHANNEL  */}
            <AuthRoute
              path={`${CHANNEL_PATH}/account`}
              exact
              component={channelAccountListComponent}
            />
            <AuthRoute
              path={`${CHANNEL_PATH}/`}
              exact
              component={channelListComponent}
            />
            <AuthRoute
              path={`${CHANNEL_PATH}/create/:channelCode`}
              exact
              component={channelCreateComponent}
            />
            <AuthRoute
              path={`${CHANNEL_PATH}/update/:channelCode/:channelAccountId`}
              exact
              component={channelUpdateComponent}
            />
            {/* PRODUK */}
            <AuthRoute
              path={`${PRODUCT_PATH}`}
              exact
              component={listProductComponent}
            />
            {/* ORDER */}
            <AuthRoute
              path={`${ORDER_PATH}`}
              exact
              component={listOrderComponent}
            />
            {/* STOCK */}
            <AuthRoute
              path={`${STOCK_PATH}`}
              exact
              component={listStockComponent}
            />
            {/* PRODUK */}
            <AuthRoute
              path={`${PLUGIN_PATH}`}
              exact
              component={pluginComponent}
            />
            {/* ACCOUNT */}
            <AuthRoute
              path={`${CONFIGURATION_PATH}/:accountId/${DETAIL}`}
              exact
              component={configurationDetail}
            />
            <AuthRoute
              path={`${CONFIGURATION_PATH}/:storeId/${CREATE}`}
              exact
              component={configurationCreate}
            />
            <AuthRoute
              path={`${CONFIGURATION_PATH}/:storeId/:accountId/${UPDATE}`}
              exact
              component={configurationUpdate}
            />
            {/* AUTH */}
            {/* <Route
              path={`${AUTHENTICATION_PATH}/:lang/:storeId/:token`}
              component={authenticationComponent}
            /> */}
            {/* AUTH | Rebuild 08/31/2021 */}
            <Route
              path={`${AUTHENTICATION_PATH}/:lang`}
              component={authenticationRFComponent}
            />
            {/* ERROR PAGE */}
            <Route path='/404' exact component={pageNotFoundComponent} />
            <Route path='/401' exact component={unautorizeComponent} />
            {/* PAGE NOT EXIST */}
            <Route path='*'>
              <Redirect to='/404' />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </>
  );
}
