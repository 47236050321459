import { merge } from 'lodash';

// en
const enConfigurationJson = require('../../../assets/i18n/en/en-configuration.json');
// id
const idConfigurationJson = require('../../../assets/i18n/id/id-configuration.json');

export default {
  en: {
    translation: merge(enConfigurationJson)
  },
  id: {
    translation: merge(idConfigurationJson)
  }
};
