import {
  CREATE_CONFIGURATION,
  GET_CONFIGURATION,
  SET_ERROR_CONFIGURATION,
  SET_LOADING_CONFIGURATION,
  UPDATE_CONFIGURATION,
} from '../constants/configuration-type';

const initialState = {
  data: [],
  loading: true,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CONFIGURATION:
    case CREATE_CONFIGURATION:
    case UPDATE_CONFIGURATION:
      return {
        ...state,
        data: payload,
        loading: false,
        error: false,
      };
    case SET_ERROR_CONFIGURATION:
      return {
        ...state,
        data: payload,
        loading: false,
        error: true,
      };
    case SET_LOADING_CONFIGURATION:
      return {
        ...state,
        data: payload,
        loading: true,
        error: false,
      };
    default:
      return state;
  }
};
