import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { IRoute } from '../router';

function AuthRoute(props : IRoute) {

  let login = false;
  if (localStorage.params) {
    login = true;
  }

  return (
    !login ? <Route render={() => <Redirect to='/' />} /> : <Route {...props}/>
  );
}


export default AuthRoute;
