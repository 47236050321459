import { call, put, takeLatest } from 'redux-saga/effects';

import { IActoinMain } from '../actions/action';
import actions from '../actions/main-action';
import {
  GET_CONFIGURATION,
  GET_REQUEST_CONFIGURATION,
  PATH_CONFIGURATION,
  SET_ERROR_CONFIGURATION,
  SET_LOADING_CONFIGURATION,
} from '../constants/configuration-type';
import { GET } from '../constants/http-method-type';

function* getConfiguration() {
  try {
    yield put({ type: SET_LOADING_CONFIGURATION });
    const data = yield call(() =>
      actions<IActoinMain>({ path: PATH_CONFIGURATION, action: GET })
    );
    yield put({ type: GET_CONFIGURATION, payload: data });
  } catch (error) {
    yield put({type: SET_ERROR_CONFIGURATION, payload: error });
  }
}

export default function* config(){
  yield takeLatest(GET_REQUEST_CONFIGURATION, getConfiguration);
}
