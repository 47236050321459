import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from './resource';

const params: {lang: string} = JSON.parse(localStorage.getItem('params'));

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: false,
    lng: params?.lang || 'id',
    fallbackLng: 'id',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
 });

export default i18n;
