// PATH
export const CHANNEL_PATH = '/channel';
export const DASHBOARD_PATH = '/dashboard';
export const PRODUCT_PATH = '/product';
export const ORDER_PATH = '/order';
export const STOCK_PATH = '/stock';
export const PENDING_SYNC_STOCK_PATH = 'sync/stock/pending';
export const CONFIGURATION_PATH = '/channel/create';
export const PROGRESS_PATH = '/progress';
export const PLUGIN_PATH = '/plugin';
export const AUTHENTICATION_PATH = '/authentication';
export const CHANNEL_ACCOUNT_PATH = '/channel/account';

// REUSABLE PATH
export const CREATE = 'create';
export const UPDATE = 'update';
export const DELETE = 'del';
export const DETAIL = 'detail';
// TAB KEY
export const DASHBOARD = 'dashboard';
export const PRODUCT = 'product';
export const DETAIL_PRODUCT = 'detail_product'
export const ORDER = 'order';
export const STOCK = 'stock';
export const PENDING_SYNC_STOCK = 'pending_sync_stock';
export const CONFIGURATION_CREATE = 'configuration_create';
export const CONFIGURATION_UPDATE = 'configuration_update';
export const CONFIGURATION_DETAIL = 'configuration_detail';
export const PLUGIN = 'plugin'
export const PROGRESS = 'progress';

export const keysNav = ['dashboard', 'product', 'order', 'stock', 'configuration', 'plugin', 'progress'];
