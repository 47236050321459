export const POST = 'POST';
export const PUT = 'PUT';
export const GET = 'GET';
export const DELETE = 'DELETE';
export const PATCH = 'PATCH';

export const HEAD = 'HEAD';
export const CONNECT = 'CONNECT';
export const OPTIONS = 'OPTIONS';
export const TRACE = 'TRACE';
