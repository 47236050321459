export const ALL = 'all';
export const VALIDATE = 'validate'
export const SUCCESS = 'success';
export const FAILED = 'failed';
export const PROGRESS = 'pending';
export const EXPORT_PATH = '/export';
export const IMPORT_PATH = '/import';
export const EXPORT_PROGRESS = '/export/progess';
export const PRODUCT_DETAIL_PATH = '/product/detail';
export const PRODUCT_LINK_PATH = '/link/all';
export const PRODUCT_UNLINK_PATH = '/unlink/all';
