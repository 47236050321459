import { DELETE, GET, PATCH, POST, PUT } from '../constants/http-method-type';
import { IActoinMain } from './action';

async function mainAction<T extends IActoinMain>(params: T): Promise<any> {
  try {
    switch (params.action) {
      case GET:
        return 'GET';
      case POST:
        return 'POST';
      case PATCH:
        return 'PATCH';
      case PUT:
        return 'PUT';
      case DELETE:
        return 'DELETE';
    }
  } catch (error) {
    return error;
  }
}

export default mainAction;
