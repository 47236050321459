import React from 'react';
import { Spinner } from '../../feedback/spinner/spinner.component';
import { LoadingComponent } from '../../general/loading/loading.component';
import './lazy-page.component.scss';
export const LazyPageComponent = (props) => {

  return <>
    <div className="lazy-page-wrapper">
      <Spinner spinning={true} indicator={<LoadingComponent />}></Spinner>
    </div>
  </>
};
