export const SET_LOADING_CONFIGURATION = 'SET_LOADING_CONFIGURATION';
export const SET_ERROR_CONFIGURATION = 'SET_ERROR_CONFIGURATION';
export const CREATE_CONFIGURATION = 'CREATE_CONFIGURATION';
export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION';
export const GET_CONFIGURATION = 'GET_CONFIGURATION';
export const DOC_CONFIGURATION = 'DOC_CONFIGURATION'; // for combine document model configuration

export const GET_REQUEST_CONFIGURATION = 'GET_REQUEST_CONFIGURATION';

export const PATH_CONFIGURATION = 'saleschannel/config';
